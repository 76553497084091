import boolean from './boolean'
import date from './date'
import image from './image'
import number from './number'
import object from './object'
import password from './password'
import text from './text'
import timestamp from './timestamp'
import get from 'lodash/get'

const fns = {
  checkbox: boolean,
  boolean,
  date,
  image,
  number,
  object,
  password,
  punchcard: number,
  timestamp
}

export default (
  value,
  schema,
  options = {}
) => {
  schema = schema || { type: 'text' }

  // Allow for schemas and options to provide custom formatters
  const render = options.render || schema.render
  if (typeof render === 'function') {
    return render({ value, schema, options })
  }

  if (get(schema, 'multiple')) {
    if (!value || (Array.isArray(value) && !value.length)) {
      return ''
    }
    if (!Array.isArray(value)) {
      return renderValue(value, schema, options)
    } else {
      return options.returnObject
        ? value.map(v => renderValue(v, schema, options))
        : value.map(v => renderValue(v, schema, options)).join(', ')
    }
  } else {
    if ([undefined, null].includes(value) && !['checkbox', 'boolean'].includes(schema.type) && !schema.render) {
      return ''
    }
    return renderValue(value, schema, options)
  }
}

const renderValue = (value, schema, options) => {
  if (Array.isArray(get(schema, 'options')) && schema.options.length && !schema?.create) {
    const option = schema.options.find(option => option.value + '' === value + '')
    if (!option) {
      return 'Invalid Entry: ' + value
    }
    return option.label || option.value
  }

  if (schema.resource && options.models) {
    const Model = Object.values(options.models).find(m => m.entity === schema.resource)
    if (Model) {
      const record = Model.find(value)
      if (record && (record.friendlyName || record.name)) {
        return record.friendlyName || record.name
      }
    }
  }

  if (typeof schema?.render === 'function') {
    return schema.render({ value, schema, options })
  }

  if (Object.keys(fns).includes(schema.type)) {
    return fns[schema.type](value, schema, options)
  }
  return text(value, schema, options)
}
