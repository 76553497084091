<template>
  <div class="cg-card-editor">
    <p>Please enter the Stripe Payment Method ID below.</p>
    <v-text-field
      label="Stripe Payment Method ID"
      :value="recordChanged.data?._stripe_id"
      @input="$handleChange('record', 'data._stripe_id', $event)"
      outlined
      dense
      v-bind="{ disabled }"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    recordChanged () {
      return this.value || {}
    }
  },
  methods: {
    $handleChange (key, path, value) {
      this.$emit('change', [key, path, value])
    }
  }
}
</script>
