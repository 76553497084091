<template>
  <div>
    <editor-card
      name="Add Conditions"
      wide-content
    >
      <filter-ui
        :value="value && value.filter"
        :schemas="filterSchemas"
        dense
        outlined
        v-bind="{ disabled }"
        allow-custom-path
        @input="$emit('change', ['filter', $event])"
        optInToModulus
      />
    </editor-card>
    <occur
      :value="value && value.occur"
      noun="shipment"
      @input="$emit('change', ['occur', $event])"
      dense
    />
    <block-payload-copy-table
    />
  </div>
</template>

<script>
import Occur from 'ui/blocks/components/Occur'
import TriggerBlockFilterMixin from '../TriggerBlockFilterMixin.js'
import BlockPayloadCopyTable from 'ui/blocks/BlockPayloadCopyTable'
// import createOrderFinishedSchemas from 'shared/schemas/events/OrderFinished.js'

export default {
  mixins: [
    TriggerBlockFilterMixin
  ],
  components: {
    Occur,
    BlockPayloadCopyTable
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    block: {
      type: Object,
      required: true
    }
  },
  computed: {
    createBlockSchemas () {
      return () => []
    }
  },
  async created () {
    if ([null, undefined].includes(this.value) || !Object.keys(this.value).length) {
      this.$emit('change', ['occur', { frequency: 'EVERY', n: 1, start: 1 }])
    }
  }
}
</script>
