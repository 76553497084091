<template>
  <v-card
    class="cg-editor"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
    >
      <v-toolbar-title>{{ recordId ? 'Edit' : 'Add New' }} Payment Source</v-toolbar-title>

      <v-spacer />
      <v-progress-circular
        v-if="loading"
        color="white"
        indeterminate
      />
      <v-btn
        icon
        @click="requestClose"
        :disabled="disabled"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      fluid
    >
      <v-alert
        v-if="saveError"
        color="error"
        dark
      >{{ saveError }}</v-alert>

      <v-btn
        v-if="type"
        icon
        @click="saveError = null,$clearChanges(),$handleChange('record', 'data', null),$handleChange('record', 'type', null)"
        :disabled="disabled"
      >
        <v-icon>fa fa-arrow-left</v-icon>
      </v-btn>
      <card-editor
        v-if="type === 'CARD'"
        :value="recordChanged"
        @change="$handleChange($event[0], $event[1], $event[2])"
        :disabled="loading"
        :environment="environment"
      />
      <ach-editor
        v-else-if="type === 'ACH'"
        :value="recordChanged"
        @change="$handleChange($event[0], $event[1], $event[2])"
        :disabled="loading"
        @loading="loading = $event"
        :environment="environment"
      />
      <shopify-editor
        v-else-if="type === 'SHOPIFY'"
        :value="recordChanged"
        @save="save"
        :disabled="loading"
        @loading="loading = $event"
        :environment="environment"
      />
      <stripe-editor
        v-else-if="type === 'STRIPE'"
        :value="recordChanged"
        @change="$handleChange($event[0], $event[1], $event[2])"
        @save="save"
        :disabled="loading"
        @loading="loading = $event"
        :environment="environment"
      />
      <div
        v-else-if="type"
      >
        Unsupported payment method type.
      </div>
      <div
        v-if="!type"
        style="max-width: 550px; margin: 0 auto; min-height: 100%;"
        class="fill-height d-flex align-center"
      >
        <div class="fill-width">
          <div class="mb-5 text-center"><strong>Please select payment source type:</strong></div>
          <v-row>
            <v-col
              :cols="12"
              :sm="6"
            >
              <icon-card
                style="margin: 0 auto;"
                icon="fas fa-credit-card"
                iconSize="50"
                color="success"
                title="Credit Card"
                v-bind="{ disabled }"
                @click="setType('CARD')"
              />
            </v-col>
            <v-col
              :cols="12"
              :sm="6"
            >
              <icon-card
                style="margin: 0 auto;"
                icon="fas fa-university"
                iconSize="50"
                color="info"
                title="ACH"
                v-bind="{ disabled }"
                @click="setType('ACH')"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="$restrict({ 'user.level': { $gte: 5 } })"
            justify-center
          >
            <v-col
              :cols="12"
              :sm="6"
            >
              <icon-card
                style="margin: 0 auto;"
                icon="fa-brands fa-cc-stripe"
                iconSize="50"
                color="warning"
                title="Stripe ID (Admins Only)"
                v-bind="{ disabled }"
                @click="setType('STRIPE')"
              />
            </v-col>
            <v-col
              :cols="12"
              :sm="6"
              v-if="$restrict({ 'addons.type': 'SHOPIFY', 'user.level': { $gte: 5 } })"
              class="text-center"
            >
              <icon-card
                style="margin: 0 auto;"
                icon="fa-brands fa-shopify"
                iconSize="50"
                color="purple"
                title="Shopify (Admins Only)"
                v-bind="{ disabled }"
                @click="setType('SHOPIFY')"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <v-checkbox
        v-if="canSetAsDefault"
        class="mt-0"
        label="Make Default Payment Method"
        :input-value="recordChanged.default"
        @click="$handleChange('record', 'default', !recordChanged.default)"
        dense
        :disabled="loading"
        hide-details
      />
    </v-container>
    <div
      v-if="!loading"
      class="footer pa-2"
    >
      <v-btn
        color="warning"
        outlined
        @click="requestClose"
        :disabled="disabled"
      >Cancel</v-btn>
      <v-tooltip
        top
        :disabled="!error"
      >
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn
              color="primary"
              class="ml-2"
              @click="save()"
              :disabled="disabled || !isValid"
            >Save &amp; Close</v-btn>
          </span>
        </template>
        <div>{{ error }}</div>
      </v-tooltip>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { PaymentSource } from 'ui/models'
import createChangeTrackerMixin from 'ui/mixins/createChangeTrackerMixin'
import CardEditor from './CardEditor'
import AchEditor from './AchEditor'
import ShopifyEditor from './ShopifyEditor'
import StripeEditor from './StripeEditor'
import IconCard from 'ui/components/IconCard'

const environment = (process.env.NODE_ENV === 'production' || (window.location.href + '').includes('stripe_production'))
  ? 'production'
  : 'sandbox'

const stripePublishableToken = {
  production: 'pk_T2g25CgROlp0YQTrsFv5BUd7sB0Xu',
  sandbox: 'pk_lFpk7CUXKHD6wdeZZtgGkBE2cY51A'
}[environment]

export default {
  oWindow: {
    width: 600,
    height: 675,
    persistent: true,
    overlay: true
  },
  mixins: [
    createChangeTrackerMixin({ path: 'record' })
  ],
  components: {
    IconCard,
    CardEditor,
    AchEditor,
    ShopifyEditor,
    StripeEditor
  },
  props: {
    recordId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      newRecordId: null,
      loading: false,
      saveError: null
    }
  },
  computed: {
    ...mapGetters(['account']),
    environment () {
      return environment
    },
    disabled () {
      return this.loading
    },
    error () {
      if (!this.type) {
        return 'Select payment source type.'
      }

      if (this.type === 'CARD') {
        if (!this.recordChanged?.private?.number) {
          return 'Enter Card Number.'
        }
        if (!this.recordChanged?.private?.cvc) {
          return 'Enter Security Code.'
        }
        if (!this.recordChanged?.data?.exp_month) {
          return 'Enter Expiration Month.'
        }
        if (!this.recordChanged?.data?.exp_year) {
          return 'Enter Expiration Year.'
        }
        if (!this.recordChanged?.data?.address_zip) {
          return 'Enter Postal Code.'
        }
        if (!this.record?._id && !this.recordChanged.allowTemporaryAuth) {
          return 'Please acknowledge the temporary $5 hold.'
        }
      } else if (this.type === 'ACH') {
        // Manual verification route
        if (this.recordChanged?.data?.ach_manual) {
          if (!this.recordChanged?.private?.account_holder_name) {
            return 'Enter Account Holder Name.'
          }
          if (!this.recordChanged?.private?.account_holder_type) {
            return 'Select Account Holder Type.'
          }
          if (!this.recordChanged?.private?.routing_number) {
            return 'Enter Routing Number.'
          }
          if (!this.recordChanged?.private?.account_number) {
            return 'Enter Account Number.'
          }
        } else if (!this.recordChanged?.data?.stripe_token) {
          return 'Please sign in to select your bank account.'
        }
      }

      return null
    },
    isValid () {
      return !this.error
    },
    canSetAsDefault () {
      // Already is default
      if (this.record?.default) {
        return false
      }
      // Failed charge methods cannot be added
      if (this.record?.failed_charge_at) {
        return false
      }
      // Card will always verify with processor.  CHECK/OTHER can just exist.
      if (['CARD', 'CHECK', 'OTHER'].includes(this.recordChanged.type)) {
        return true
      }
      if (this.recordChanged.type === 'ACH') {
        if (this.recordChanged?.data?.ach_manual) {
          if (this.record?.verified) {
            return true
          }
        } else if ((this.recordChanged.data && (this.recordChanged.data.stripe_token || this.recordChanged.data._stripe_id))) {
          return true
        }
      }
      return false
    },
    record () {
      return PaymentSource.find(this.recordId || this.newRecordId)
    },
    type () {
      return this.recordChanged.type
    }
  },
  methods: {
    async initStripe () {
      const stripePromise = new Promise((resolve) => {
        require('scriptjs')('https://js.stripe.com/v2/', resolve)
      })
      try {
        await stripePromise
        window.Stripe.setPublishableKey(stripePublishableToken)
      } catch (e) {
        window.alert('Could not load Stripe integration.  This editor will close.', 'Error')
        this.$emit('close')
      }
    },
    async setType (type) {
      this.loading = true
      try {
        if (['CARD', 'ACH'].includes(type)) {
          await this.initStripe()
        }
        this.$handleChange('record', 'type', type)
      } finally {
        this.loading = false
      }
    },
    requestClose () {
      if (this.loading) {
        return
      }
      this.$emit('close')
    },
    async save () {
      this.loading = true
      this.saveError = null

      if (this.recordChanged.private) {
        let response
        try {
          if (this.type === 'CARD') {
            response = await new Promise((resolve, reject) => {
              window.Stripe.card.createToken(
                {
                  ...this.recordChanged.private,
                  exp_month: this.recordChanged.data?.exp_month,
                  exp_year: this.recordChanged.data?.exp_year,
                  address_zip: this.recordChanged?.data?.address_zip
                },
                (status, response) => {
                  if (response.error) {
                    reject(response.error)
                  } else {
                    resolve(response)
                  }
                })
            })
          } else if (this.type === 'ACH') {
            response = await new Promise((resolve, reject) => {
              window.Stripe.bankAccount.createToken(
                {
                  country: 'US',
                  currency: 'USD',
                  ...this.recordChanged.private
                },
                (status, response) => {
                  if (response.error) {
                    reject(response.error)
                  } else {
                    resolve(response)
                  }
                }
              )
            })
          } else {
            throw new Error('Unknown method type.')
          }
          console.log('stripe response', response)
          if (!response.id) {
            throw new Error('Could not receive stripe token.')
          }
          this.$handleChange('record', 'data.stripe_token', response.id)
        } catch (e) {
          this.saveError = e.response?.data?.error || e.message
          this.loading = false
          throw e
        }
      }
      //
      try {
        const result = await PaymentSource.save({
          ...this.recordChanged,
          private: undefined // Pass everything except private data
        })
        const record = PaymentSource.find(result?.entities?.payment_sources?.[0]?._id)
        if (!this.recordId) {
          this.newRecordId = record._id
        }

        this.$clearChanges()
        this.$emit('close', { record })
      } catch (e) {
        this.saveError = e.response?.data?.error || e.message
      } finally {
        this.loading = false
      }
    }
  },
  async created () {
    if (this.recordId) {
      this.loading = true
      try {
        await PaymentSource.fetchOne(this.recordId, { force: true })
      } catch (e) {
        this.$emit('close')
        throw e
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
