<template>
  <v-card
    class="cg-editor"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
    >
      <v-toolbar-title>Accept ACH Terms</v-toolbar-title>

      <v-spacer />

      <v-btn
        icon
        @click="$emit('close')"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      fluid
    >
      <ach-terms />
    </v-container>
    <div
      class="footer pa-2">
      <v-btn
        color="primary"
        outlined
        @click="$emit('close')"
      >Close</v-btn>
      <v-btn
        color="primary"
        class="ml-2"
        @click="$emit('close', { accept: true })"
      >Accept</v-btn>
    </div>
  </v-card>
</template>

<script>
import AchTerms from './AchTerms'

export default {
  components: {
    AchTerms
  }
}
</script>
