<template>
  <div>
    <div
      class="text-center d-flex align-center"
      style="min-height: 100%"
    >
      <div
        v-if="value._id && !value.verified && value.data && value.data.ach_manual"
      >
        Verify deposit amounts
      </div>
      <div
        v-else-if="!value._id"
        class="fill-width"
      >
        <div
          v-if="value && value.data && value.data.ach_manual"
        >
          <div
            class="text-center"
          >
            <p>Please enter your bank account details.</p>
            <v-row
              class="flex-wrap"
            >
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Account Holder Name"
                  outlined
                  dense
                  :value="value && value.private && value.private.account_holder_name"
                  @input="$emit('change', ['record', 'private.account_holder_name', $event])"
                  hide-details
                  :disabled="loading || disabled"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  label="Account Type"
                  :items="[{ text: 'Individual', value: 'individual' }, { text: 'Company', value: 'company' }]"
                  outlined
                  dense
                  :value="value && value.private && value.private.account_holder_type"
                  @input="$emit('change', ['record', 'private.account_holder_type', $event])"
                  hide-details
                  :disabled="loading || disabled"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Routing Number"
                  outlined
                  dense
                  :value="value && value.private && value.private.routing_number"
                  @input="$emit('change', ['record', 'private.routing_number', $event])"
                  hide-details
                  :disabled="loading || disabled"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Account Number"
                  outlined
                  dense
                  :value="value && value.private && value.private.account_number"
                  @input="$emit('change', ['record', 'private.account_number', $event])"
                  hide-details
                  :disabled="loading || disabled"
                />
              </v-col>
              <v-col
                cols="12"
                class="text-center"
              >* - Two small deposits will be added to this account over the next few days.  In order to use this payment method you must come back to this page, click "Verify ACH", and verify those two deposit amounts.  This payment source will be invalid until you do this.</v-col>
            </v-row>
            <br />
            <br />
            <br />
            <v-btn
              color="primary"
              text
              @click="$emit('change', ['record', 'data.ach_manual', false])"
              style="text-transform: none"
              :disabled="loading || disabled"
            >...or click here to electronically sign in to your bank account.</v-btn>
          </div>
        </div>
        <div
          v-else-if="plaidResponse"
          class="elevation-3 my-3"
        >
          <div
            class="mt-7 mb-4"
            style="margin: 0 auto; display: inline-block;"
          >
            <div class="d-flex">
              <div>
                <v-icon style="font-size: 50px">fas fa-university</v-icon>
              </div>
              <div
                class="ml-4 text-left"
              >
                <strong>Selected Account</strong>
                <p class="mt-1">{{ plaidResponseSelectionName }}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
        >
          <br />
          <br />
          <v-btn
            color="accent"
            large
            :disabled="disabled || loading"
            @click="openPlaid"
          ><v-icon>fas fa-university</v-icon> &nbsp;&nbsp; SIGN IN TO YOUR BANK</v-btn>
          <br />
          <br />
          <br />
          <v-btn
            color="primary"
            text
            @click="$emit('change', ['record', 'data.ach_manual', true])"
            style="text-transform: none"
          >...or click here to manually enter your banking details.</v-btn>
        </div>
        <div
          class="pa-6"
          style="font-size: 10px;"
        >
          <ach-terms />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AchTerms from './AchTerms'

export default {
  components: {
    AchTerms
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    environment: {
      type: String,
      default: 'production'
    }
  },
  data () {
    return {
      loading: false,
      plaidInstance: null,
      plaidResponse: null,
      manual: false
    }
  },
  computed: {
    plaidResponseSelectionName () {
      if (!this.plaidResponse) {
        return ''
      }
      return this.plaidResponse.institution?.name + ': ' + this.plaidResponse.account?.name + ' (' + this.plaidResponse.account?.mask + ')'
    }
  },
  watch: {
    loading (value) {
      this.$emit('loading', value)
    }
  },
  methods: {
    $handleChange (key, path, value) {
      this.$emit('change', [key, path, value])
    },
    async openPlaid () {
      if (!this.plaidInstance) {
        await this.initPlaid()
      }
      if (!this.plaidInstance) {
        return
      }
      this.loading = true
      this.plaidInstance.open()
      setTimeout(() => {
        this.loading = false
      }, 2000)
    },
    async initPlaid () {
      this.loading = true
      // this.paymentSource.data = {}
      const plaidPromise = new Promise((resolve) => {
        const url = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js'
        require('scriptjs')(url, resolve)
      })
      try {
        const result = await this.$http({
          method: 'POST',
          url: '/v2/payment_sources/plaid_link_token',
          data: {
            environment: this.environment
          }
        })
        await plaidPromise
        this.plaidInstance = await new Promise((resolve, reject) => {
          try {
            const plaidInstance = window.Plaid.create({
              token: result.data.link_token,
              onLoad: () => {
                resolve(plaidInstance)
              },
              onSuccess: (publicToken, meta) => {
                console.log('plaid success', publicToken, meta)
                this.plaidResponse = meta
                this.retrieveStripeToken(publicToken, meta.account_id)
              },
              onExit: (err, meta) => {
                console.log('plaid error', err, meta)
                this.plaidResponse = null
              },
              onEvent: (name, meta) => {
                console.log('plaid event', name, meta)
              }
            })
          } catch (e) {
            throw new Error('Could not initialize Plaid: ' + e.message)
          }
        })
      } catch (e) {
        window.alert('Could not load Plaid integration: ' + e.message, 'Error')
      } finally {
        this.loading = false
      }
    },
    async retrieveStripeToken (publicToken, bankAccountId) {
      this.loading = true
      try {
        const response = await this.$http({
          method: 'POST',
          url: '/v2/payment_sources/plaid_stripe_token',
          data: {
            environment: this.environment,
            public_token: publicToken,
            account_id: bankAccountId
          }
        })

        if (!response.data?.stripe_bank_account_token) {
          window.alert('Could not retrieve stripe bank account token.')
          return
        }
        this.$handleChange('record', 'data.stripe_token', response.data.stripe_bank_account_token)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
