// Returns an object of all schemas added based on the roller addon

const getSchemas = ({ addon } = {}) => {
  return {
    'contact._roller_id': {
      type: 'number',
      immutable: true,
      name: 'Roller Customer ID'
    },
    'contact._roller_created_at': {
      type: 'timestamp',
      immutable: true,
      name: 'Roller Created At'
    }
  }
}

export {
  getSchemas
}

export default {
  getSchemas
}
