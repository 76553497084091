<template>
  <div>
    <p>By proceeding, you authorize Patch Retention to debit the bank account specified above for any amount owed for charges arising from your use of Patch Retention's services and/or purchase of products from Patch Retention, pursuant to Patch Retention's website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to Patch Retention with 30 (thirty) days notice.</p>
    <p>If you use Patch Retention's services or purchase additional products periodically pursuant to Patch Retention's terms, you authorize Patch Retention to debit your bank account periodically. Payments that fall outside of the regular debits authorized above will only be debited after your authorization is obtained.</p>
  </div>
</template>

<script>
export default {}
</script>
